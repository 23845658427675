import { NextPage, NextPageContext } from 'next';
import React from 'react';
import { SSRPageProps, SSRPageProvider } from '../components/SSRPage/SSRPage';
import { LandingPage } from '../page-components/LandingPage/LandingPage';

const Page: NextPage<any> = (props) => <SSRPageProvider props={props} SSRComp={LandingPage} />;

Page.getInitialProps = (context: NextPageContext) => SSRPageProps(LandingPage, context);

export default Page;
